<template>
  <v-sheet class="promotion" id="promotion">

    <v-row>
      <v-col md="6" class="my-auto">
        <h1 class="custom-header-blue-text m-0">{{ pageTitle() }}</h1>
      </v-col>
      <v-col md="6" class="text-right">
        <v-btn v-if="promotion.is_active !=1" :loading="pageLoading" class="white--text mr-2" depressed color="blue darken-4" tile
          v-on:click="updateOrCreate(2)">
          Save as Draft
        </v-btn>
        <v-btn :loading="pageLoading" class="white--text mr-2" depressed color="blue darken-4" tile
          v-on:click="updateOrCreate(1)">
          Save
        </v-btn>
        <v-btn class="ml-2" :disabled="pageLoading" depressed tile v-on:click="goBack()">
          Cancel
        </v-btn>
      </v-col>
      <v-col md="10">
        <v-form ref="customerForm" v-on:submit.stop.prevent="updateOrCreate">
          <v-container fluid>
            <v-row>
              <v-col md="3" class="my-auto py-0">
                <label for="title" class="btx-label mt-2 ">Category</label>
              </v-col>
              <v-col md="9" class="py-0">
                <v-btn-toggle v-model="promotion.category_type" tile group divided color="blue" variant="plain" mandatory>
                  <v-btn color="green" height="34" variant="flat" class="my-0" value="members"
                    style="border: 2px solid rgb(34, 167, 93)">
                    Members
                  </v-btn>

                  <!-- <v-btn
                    color="red"
                    variant="flat"
                    class="my-0"
                    value="premium"

                    style="border: 2px solid #c42f22"
                    height="34"
                  >
                    Premium
                  </v-btn> -->
                </v-btn-toggle>
              </v-col>
              <v-col md="3" class="my-auto py-0">
                <label for="title" class="btx-label mt-2 required">Title</label>
              </v-col>
              <v-col md="9" class="py-1">
                <TextInput v-model="promotion.title" :disabled="pageLoading" :loading="pageLoading"
                  :rules="[vrules.required(promotion.title, 'Title')]" :class="{ required: !promotion.title }"
                  hide-details placeholder="Title">
                </TextInput>
              </v-col>
              <v-col md="3" class="py-0">
                <label for="description" class="btx-label mt-2">Description</label>
              </v-col>
              <v-col md="9" class="py-0">
                <TextAreaInput v-model="promotion.long_description"  v-on:keypress="(e) => manageLimit(e)"  v-on:paste="(e) => onPaste(e)" :disabled="pageLoading" :loading="pageLoading"
                  placeholder="Description" :counter="250" :rows="7" :cols="30">
                </TextAreaInput>
              </v-col>
              <v-col md="3" class="my-auto py-1">
                <label for="date-of-start" class="btx-label mt-2 required">Start Date</label>
              </v-col>
              <v-col md="3" class="py-1">
                <DatePicker hide-details :disabled="pageLoading" :loading="pageLoading" id="date-of-start"
                  v-model="promotion.date_of_start" :rules="[vrules.required(promotion.date_of_start, 'Date Of Start')]"
                  :class="{ required: !promotion.date_of_start }" @change="minDates()" placeholder="Start Date">
                </DatePicker>
              </v-col>

              <v-col md="3" class="text-right py-1"><label for="title" class="btx-label mt-2 required">Exp.
                  Date</label></v-col>
              <v-col md="3" class="py-1">



                <DatePicker :key="rerenderKey" hide-details :disabled="pageLoading" :loading="pageLoading"
                  id="date-of-start" :rules="[vrules.required(promotion.date_of_exp, 'Date Of Exp')]"
                  :class="{ required: !promotion.date_of_exp }" v-model="promotion.date_of_exp"
                  :minDate="promotion.date_of_start" placeholder="Exp. Date">
                </DatePicker>


              </v-col>

              <v-col md="3" class="my-auto py-1">
                <label for="publish-date" class="btx-label required">Publish Date</label>
              </v-col>
              <v-col md="3" class="py-1">
                <DatePicker hide-details :disabled="pageLoading" :loading="pageLoading" id="date-of-publish"
                  v-model="promotion.date_of_publish" placeholder="Publish"></DatePicker>
              </v-col>
              
              <v-col cols="6"> </v-col>
              <!-- <v-col md="3" class="my-auto py-0">
                <label for="title" class="btx-label mt-2 ">Status</label>
              </v-col>
              <v-col md="9" class="py-1">
                <v-radio-group
                  id="template_type"
                  hide-details
                  dense
                  mandatory
                  row
                  style="width: 100%; padding: 0px 8px"
                  class="d-flex"
                  v-model="promotion.is_active"
                >
                    <v-col md="4">
                      <v-radio
                        :value="3"
                        class="font-level-2-bold px-4 p-2 mr-0 d-flex justify-space-between"
                        label="SMS"
                        :class="promotion.is_active == '3' ? 'orange lighten-4' : 'grey lighten-5'"
                        :color="promotion.is_active == '3' ? 'orange ' : ''"
                        text-color="white"
                        icon="mdi-message-outline"
                        x-large
                        style="background-color: #f2d8b8; width: auto"
                        :style="[
                          promotion.is_active == '3'
                            ? { border: ' 1px solid #e08310 !important' }
                            : { border: '1px solid #ECEFF1 !important' },
                        ]"
                      >
                        <template v-slot:label>
                          <div
                            class="d-flex justify-space-between align-center"
                            style="width: 100%"
                          >
                            <span class="font-level-3-bold"> Auto Active on Start Date </span>
                           
                          </div>
                        </template>
                      </v-radio>
                      </v-col>
                      <v-col md="4">
                      <v-radio
                        :value="1"
                        class="font-level-2-bold px-4 p-2 mr-0 d-flex justify-space-between"
                        label="SMS"
                        :class="promotion.is_active == '1' ? 'orange lighten-4' : 'grey lighten-5'"
                        :color="promotion.is_active == '1' ? 'orange ' : ''"
                        text-color="white"
                        icon="mdi-message-outline"
                        x-large
                        style="background-color: blue; width: auto"
                        :style="[
                          promotion.is_active == '1'
                            ? { border: ' 1px solid #e08310 !important' }
                            : { border: '1px solid #ECEFF1 !important' },
                        ]"
                      >
                        <template v-slot:label>
                          <div
                            class="d-flex justify-space-between align-center"
                            style="width: 100%"
                          >
                            <span class="font-level-3-bold"> Active Now </span>
                            
                          </div>
                        </template>
                      </v-radio>
                    </v-col>
                    <v-col md="4">
                      <v-radio
                        :value="2"
                        class="font-level-2-bold px-4 p-2 mr-0 d-flex justify-space-between"
                        label="SMS"
                        :class="promotion.is_active == '2' ? 'orange lighten-4' : 'grey lighten-5'"
                        :color="promotion.is_active == '2' ? 'orange ' : ''"
                        text-color="white"
                        icon="mdi-message-outline"
                        x-large
                        style="background-color:blue; width: auto"
                        
                      >
                        <template v-slot:label>
                          <div
                            class="d-flex justify-space-between align-center"
                            style="width: 100%"
                          >
                            <span class="font-level-3-bold"> Save as Draft </span>
                            
                          </div>
                        </template>
                      </v-radio>
                    </v-col>
                    </v-radio-group>
              </v-col> -->
              <v-col cols="3">
                <label for="publish-date" class="btx-label">Images</label>
              </v-col>

              <v-col cols="3">
                <label class="file_upload" for="file-upload"><span style="color: white; font-weight: 600">Upload
                    Images</span></label>
                <input type="file" id="file-upload" multiple @change="handleFileUpload" />
                <div v-if="showModal" class="modal" @click.self="closeModal">
                  <div class="modal-content">
                    <span class="close" @click="closeModal">&times;</span>
                    <img :src="selectedImage.previewUrl" alt="Preview" class="modal-image" />
                  </div>
                </div>
              </v-col>

              <v-col cols="6"></v-col>
              <v-col md="3"> </v-col>
              <v-col md="9" class="py-0">
                <div class="image-container">
                  <v-col md="16" class="py-0">
                    <div class="image-container" :key="rerenderKey">
                      <div v-for="(image, index) in promotion.images" :key="index" class="image-item">                        
    <div>   
        <div style="min-height: 48px;"> 
          
            <img v-if="$route.params.id && image.id" :src="image?.file?.url" alt="Preview" class="profile-pic" @click="showImage(index)" />
            <img v-else :src="image.previewUrl" alt="Preview" class="profile-pic" @click="showImage(index)" />
            
            <!-- Radio button -->
            <!-- {{ image?.primary }} -->
          
            <input type="radio" v-model="image.primary" style="max-width:200px;" class="checkbox-custom" :value="true" v-on:change="managePrimary(index)" />
            
            <!-- <v-btn class="primary-check--button" icon>
                <v-icon color="grey darken-2">mdi-checkbox-blank-outline</v-icon>
            </v-btn> -->
            <button  class="remove-button"   v-on:click.stop.prevent="deleteConfirm(image.id)">
                <v-icon color="red">mdi-delete</v-icon>
            </button>
            <button v-if="!image.id" class="remove-button" @click="removeImages(index)">
                <v-icon color="red">mdi-delete</v-icon>
            </button>
        </div>
    </div>
</div>
                      <!-- </v-hover> -->
                        <!-- <button v-if="!$route.params.id && image.id " class="remove-button" @click="removeImages(index)">X</button> -->

                      </div>
                  </v-col>
                </div>
              </v-col>
            </v-row>
          </v-container>
        </v-form>
      </v-col>
    </v-row>
    <DeleteTemplate
        type="Image"
        :delete-dialog="deleteDialog"
        :delete-endpoint="deleteEndpoint"
        v-on:close="deleteDialog = false"
        v-on:delete:success="getPromotion()"
      >
      </DeleteTemplate>
  </v-sheet>
</template>

<script>
import { toSafeInteger, cloneDeep, map } from "lodash";
import TextInput from "@/view/components/TextInput";
import TextAreaInput from "@/view/components/TextAreaInput";
import DatePicker from "@/view/components/DatePicker";
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import { CREATE_PROMOTION, UPDATE_PROMOTION, FIND_PROMOTION,CREATE_PRIMARY } from "@/core/lib/promotion.lib";
import { SET_ERROR, SET_MESSAGE } from "@/core/services/store/common.module";
import DeleteTemplate from "@/view/components/DeleteTemplate";

import moment from "moment-timezone";

export default {
  name: "promotion-create",
  title: "Create Promotion",
  data() {
    return {
      pageLoading: false,
      formValid: true,
      deleteEndpoint:null,
      category_type: null,
      deleteDialog: false,
      rerenderKey: Number(new Date()),

      images: null,
      promotionId: null,
      listCountry: [
        {
          text: "Singapore",
          value: "singapore",
        },
        {
          text: "Malaysia",
          value: "malaysia",
        },
        {
          text: "Australia",
          value: "australia",
        },
      ],
      genderList: [
        {
          text: "Male",
          value: "male",
        },
        {
          text: "Female",
          value: "female",
        },
        {
          text: "Other",
          value: "other",
        },
      ],
      message: "",
      selectedImage: null,
      showModal: false,
      primaryImage:null,
      
      promotion: {
        title: null,
        description: null,
        date_of_start: null,
        date_of_exp: null,
        date_of_publish: new Date().toISOString().substr(0, 10),
        images: [],
        selectedImageIndex: 0,
        is_active:2,

      },
      nowDate: new Date().toISOString().slice(0, 10),
    };
  },
  components: {
    TextInput,
    DatePicker,
    TextAreaInput,
    DeleteTemplate
  },

  methods: {
    deleteConfirm(id ) {
        this.deleteEndpoint = `delete-image/${id}`;
        this.deleteDialog = true;
      },
    managePrimary(selectIndex)
    {
      let imageData = cloneDeep(this.promotion.images);
      this.promotion.images = map(imageData, (row, index) => {
        row.primary = false;
        if(index == selectIndex)
        {
          row.primary = true;
        }
        return row;
      });
    },
    manageLimit(e) {
      if (this.promotion.long_description &&
        this.promotion.long_description.length > 249) {
        e.preventDefault();
      }

    },
    onPaste(e) {
      let coppied = e.clipboardData.getData("Text");
      let preVal = this.promotion.long_description;
      let finalval = String(preVal) + String(coppied);
      if (Number(finalval.length) > 249) {
        let trimValue = finalval.substring(0, 250);
        this.promotion.long_description = trimValue;
        e.preventDefault();
      }
    },
    
    async markPrimary(id){
      
      this.pageLoading = true;
 
    await CREATE_PRIMARY(this.$route.params.id,id).then((data) => {
            console.log(data)
            this.getPromotion();

        })
        .catch((error) => {
          this.$store.commit(SET_ERROR, [{ model: true, message: error }]);
        })
        .finally(() => {
          this.pageLoading = false;

        });
 
    },
    minDates() {
      const pastDate = moment(this.promotion.date_of_start, "YYYY-MM-DD").add("days", 1);
      return moment(pastDate).format("YYYY-MM-DD");
    },
    selectCategory(row) {
      alert(row)
      this.category_type = row;
      console.log(this.category_type, 'category_type')
    },
    pageTitle() {
      if (this.promotionId) {
        return "Update Promotion";
      }
      return "Create Promotion";
    },
    async updateOrCreate(id=null) {
      const _this = this;
      _this.promotion.is_active=id;
      console.log(_this.promotion, '_this.promotion')
      if (!_this.$refs.customerForm.validate()) {
        return false;
      }

      try {
        _this.pageLoading = true;
        if (_this.promotionId) {
          const promotion = await UPDATE_PROMOTION(_this.promotionId, _this.promotion);
          console.log(promotion)

          _this.$router.replace({
            name: "promotion",
            query: { t: new Date().getTime() },
          });
          _this.$store.commit(SET_MESSAGE, [
            { model: true, message: "Promotion Updated Successfully." },
          ]);
        } else {
          const promotion = await CREATE_PROMOTION(_this.promotion);
          console.log(promotion)
          _this.$router.replace({
            name: "promotion",
            query: { t: new Date().getTime() },
          });
          _this.$store.commit(SET_MESSAGE, [
            { model: true, message: "Promotion Created Successfully." },
          ]);
        }
      } catch (error) {
        _this.$store.commit(SET_ERROR, [{ model: true, message: error }]);
      } finally {
        _this.pageLoading = false;
      }
    },
    async getPromotion() {
      const promotion = await FIND_PROMOTION(this.promotionId);
      console.log(promotion, 'promotion')
      this.promotion = {
        title: promotion.title,
        id: promotion.id,
        long_description: promotion.long_description,
        date_of_start: promotion.start_date,
        is_active: promotion.is_active,
        
        date_of_exp: promotion.end_date,
        date_of_publish: promotion.publish_date,
        images: promotion.transaction_documents,

        category_type: promotion.category,
        
      };
      this.rerenderKey = Number(new Date());
      this.$store.dispatch(SET_BREADCRUMB, [
        { text: "Promotion", disabled: true },
        { text: "Update", disabled: true },
        { text: promotion.barcode, disabled: true },
      ]);
    },
    onFileChange(e) {
      const files = e.target.files || e.dataTransfer.files;
      if (!files.length) return;
      this.createImage(files);
    },
    createImage(files) {
      const vm = this;
      for (var index = 0; index < files.length; index++) {
        var reader = new FileReader();
        reader.onload = function (event) {
          const imageUrl = event.target.result;
          vm.images.push(imageUrl);
        };
        reader.readAsDataURL(files[index]);
      }
    },
    removeImage(index) {
      this.images.splice(index, 1);
    },
    handleFileUpload(event) {

      const files = event.target.files;
     

      for (let i = 0; i < files.length; i++) {
        console.log(files[i],'files')
      let fileExtention = files[i]?.type.split("/");
      fileExtention = fileExtention[0];
      if (fileExtention != "image") {
        this.$store.commit(SET_ERROR, [
          { model: true, message: "Only Image required" },
        ]);
        return false;
      }
      console.log(fileExtention,'fileExtention')
        const reader = new FileReader();
        reader.onload = (e) => {
          const isFirstUpload = this.promotion.images.length === 0;

          this.promotion.images.push({
            file: files[i],
            previewUrl: e.target.result,
            primary:isFirstUpload
          });
        };
        reader.readAsDataURL(files[i]);
        console.log(this.promotion.images, 'this.promotion.images')
      }
    },
    removeImages(index) {
      this.promotion.images.splice(index, 1);
    },
    showImage(index) {
      this.selectedImage = this.images[index];
      this.showModal = true;
    },
    closeModal() {
      this.showModal = false;
      this.selectedImage = null;
    },
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { text: "Promotion", disabled: true },
      { text: "Create", disabled: true },
    ]);
    
    const { name, params } = this.$route;
    if (name === "promotion-update") {
      const { id } = params;
      if (id) {
        this.promotionId = toSafeInteger(id);
        this.getPromotion();
      } else {
        // this.goBack();
      }
    }
  },
};
</script>

<style>
.multiple_img {
  width: 30%;
  margin: auto;
  display: block;
  margin-bottom: 10px;
}

.custom-sub-header-blue-text {
  font-weight: 900 !important;
  color: #24326d !important;
  letter-spacing: 1.5px !important;
  line-height: 1.11em !important;
  text-transform: uppercase !important;
}

/* Style for the file input */
input[type="file"] {
  display: none;
}

/* Style for the file input label */
.file_upload {
  padding: 10px 20px;
  background-color: #0d47a1;
  color: #ffffff;
  font-size: 16px;
  font-weight: bold;
  cursor: pointer;
  border-radius: 4px;
  transition: background-color 0.3s ease;
}

.file_upload:hover {
  background-color: #0d47a1 !important;
}

.image-container {
  display: flex;
  flex-wrap: wrap;
  margin-top: 0px;
}

.image-item {
  position: relative;
  margin-right: 10px;
  margin-bottom: 10px;
}

.profile-pic {
  width: 180px;
  height: 180px;
  -o-object-fit: cover;
  object-fit: cover;
  cursor: pointer;
  border: 1px solid rgb(122, 121, 121);
  /* background: #0000003b; */
}
.remove-button {
  position: absolute;
  top: 25px;
  right: 17px;
  padding: 5px;
  color: white;
  border: none;
  /* border-radius: 50%; */
  cursor: pointer;
  font-weight: bold;
  clip-path: circle();
  border: 7px;
}
.remove--button {
  position: absolute;
  top: 82px;
  right: 2px;
  padding: 5px;
 
  color: white;
  border: none;
  /* border-radius: 50%; */
  cursor: pointer;
  font-weight: bold;
  clip-path: circle();
  border: 7px;
}
.primary-check--button {
  position: absolute;
  top: 50px;
  width: 20px; /* Adjust the width as needed */
    height: 20px; 
  right: 2px;
  background:#fff;
  /* border-radius: 50%; */
  cursor: pointer;
}
/* .remove-button:hover {
  background-color: #d32f2f;
} */

/* Modal styles */
.modal {
  display: block;
  position: fixed;
  z-index: 1;
  padding-top: 50px;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: rgba(0, 0, 0, 0.8);
}

.modal-content {
  margin: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 80%;
  max-width: 800px;
  height: 80%;
}

.modal-image {
  width: 100%;
  height: auto;
  object-fit: contain;
}

.close {
  position: absolute;
  top: 20px;
  right: 30px;
  font-size: 35px;
  font-weight: bold;
  color: #f1f1f1;
  cursor: pointer;
}

.close:hover,
.close:focus {
  color: #bbb;
  text-decoration: none;
  cursor: pointer;
}

button.v-btn.v-btn--active.v-btn--is-elevated.v-btn--has-bg.theme--light.v-size--default.green {
  background-color: rgb(34, 167, 93) !important;
  color: #fff;
}

button.v-btn.v-item--active.v-btn--active.v-btn--is-elevated.v-btn--has-bg.theme--light.v-size--default.red {
  background-color: rgb(196, 47, 34) !important;
  color: #fff;
}
.checkbox-custom {
  position: absolute;
  bottom: 75px;
  right:25px;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    width: 20px;
    height: 20px;
    border: 2px solid #999;
    border-radius: 4px;
    outline: none;
    cursor: pointer;
    vertical-align: middle;
    position: relative;
}

.checkbox-custom:checked {
    background-color:  rgb(34, 167, 93);
    border-color:  rgb(34, 167, 93);
}

.checkbox-custom:checked::after {
    content: "\2713";
    font-size: 14px;
    color: #fff;
    position: absolute;
    top: 0;
    left: 4px;
}

.checkbox-custom:hover {
    border-color: #0056b3;
}

.checkbox-custom:disabled {
    cursor: not-allowed;
    opacity: 0.6;
}

</style>
